import Nawigacja from "../Komponenty/Nawigacja"
import Axios from "axios";

export default function Mapa(props){
    return(
        <>
            <Nawigacja />
            <div className="tlo" />
			<div className="srodekekranu">
                <div className="glowna">
                    <h3>W przygotowaniu</h3>
                </div>
            </div>
        </>
    )
};